$special_font:'Playfair Display', serif;
$main_font:'Lato', sans-serif;

$accent_color:#6ea81f;
$theme_color1:#252525;
$theme_color2:#fbfbfb;

$header_color:#333333;
$font_color:#7d7d7d;

$white:#ffffff;

@import 'main/cookie_policy';

@mixin btn_shadow {
    -webkit-box-shadow: 5px 9px 46px rgba(172,185,191,.4);
    -moz-box-shadow: 5px 9px 46px rgba(172,185,191,.4);
    box-shadow: 5px 9px 46px rgba(172,185,191,.4);
}

@mixin btn_shadow_pushed {
    top: 3px;
    -webkit-box-shadow: 9px 15px 20px rgba(172,185,191,.6);
    -moz-box-shadow: 9px 15px 20px rgba(172,185,191,.6);
    box-shadow: 9px 15px 20px rgba(172,185,191,.6);
}

@mixin fill {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

@mixin anim {
    transition: 0.3s all ease;
}

@mixin default_bg {
    background-position: center;
    background-repeat: no-repeat;
}

.vertical_align{
    @include fill;
    > div{
        display: table;
        height: 100%;
        width: 100%;
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}

body{
    font-family: $main_font;
    h1,h2,h3,h4,h5,h6,ul,ol,a,p{
        margin: 0;
        font-weight: 400;
    }
    ul{
        list-style: none;
        padding: 0;
    }
    a{
        text-decoration: none;
        &:hover{
            text-decoration: none;
        }
    }
}

@media only screen and (max-width: 991px) {
    .text-right, .text-left{
        text-align: center;
    }
}

.page{
    overflow: hidden;
    &.subpage{
        .header{
            &.dark{
                div.menu_box{
                    nav.menu{
                        > ul{
                            > li{
                                > div{
                                    > a{
                                        color: $white;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .header_bottom{
            div.logo_box{
                padding-bottom: 30px;
            }
            div.menu_box{
                nav.menu{
                    > ul{
                        > li{
                            > div{
                                > a{
                                    color: $header_color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .footer{
            border-top: 1px solid #ebebeb;
        }
    }
}

.default_font{
    color: $font_color;
    font-size: 16px;
    line-height: 30px;
    @media only screen and (max-width: 1500px) {
        font-size: 13px;
        line-height: 27px;
    }
    h1{
        font-size: 80px;
        line-height: 90px;
        color: $header_color;
        @media only screen and (max-width: 1500px) {
            font-size: 50px;
            line-height: 60px;
        }
    }
    h2{
        font-size: 31px;
        line-height: 50px;
        @media only screen and (max-width: 1500px) {
            font-size: 26px;
            line-height: 46px;
        }
    }
    h3{
        font-size: 20px;
        line-height: 30px;
        @media only screen and (max-width: 1500px) {
            font-size: 19px;
            line-height: 29px;
        }
    }
    h4{
        font-size: 19px;
        line-height: 29px;
        @media only screen and (max-width: 1500px) {
            font-size: 18px;
         line-height: 28px;
        }
    }
    h5{
        font-size: 18px;
        line-height: 28px;
        @media only screen and (max-width: 1500px) {
            font-size: 17px;
            line-height: 27px;
        }
    }
    h6{
        font-size: 17px;
        line-height: 27px;
        @media only screen and (max-width: 1500px) {
            font-size: 16px;
            line-height: 30px;
        }
    }
    ul{
        li{
            padding-left: 25px;
            background-repeat: no-repeat;
            background-position: left top 8px;
            background-image: url('./../img/check_icon.png');
        }
    }
    ol{
        padding-left: 18px;
    }
    h1,h2,h3,h4,h5,h6,p,img,ul,ol,a{
        margin-bottom: 10px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 5px;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    h1,h2,h3,h4,h5,h6{
        color: $header_color;
    }
    a{
        color: $accent_color;
        text-decoration: underline;
    }
}

.more_btn{
    padding: 11.5px 45px;
    font-size: 13px;
    line-height: 20px;
    display: inline-block;
    color: $header_color;
    border-radius: 5px;
    text-decoration: none;
    @include anim;
    @include btn_shadow;
    text-transform: uppercase;
    &:hover{
        color: $header_color;
        @include btn_shadow_pushed;
    }
    &.accented{
        background: $accent_color;
        color: $white;
        box-shadow: none;
        &:hover{
            top: 0;
            box-shadow: none;
            background: $white;
            color: $header_color;
        }
    }
}

header.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    @include anim;
    &.dark{
        background: $theme_color1;
        > div{
            &.header_bottom{
                .logo_box{
                    padding-top: 15px;
                    padding-bottom: 15px;
                    @media only screen and (max-width: 991px) {
                        .rwd_btn{
                            top: 38px;
                        }
                    }
                }
                .menu_box{
                    > nav.menu {
                        > ul {
                            > li {
                                > div {
                                    > a{
                                        padding-top: 40px;
                                        &:before{
                                            position: absolute;
                                            bottom: auto;
                                            top: 0;
                                            left: 0;
                                            width: 0;
                                            @include anim;
                                            border-top: 2px solid $accent_color;
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    > div{
        position: relative;
        &.header_top{
            padding: 14px 0;
            background: $theme_color1;
            div.contact_info{
                padding-top: 5px;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    &:first-child{
                        color: $accent_color;
                        font-size: 11px;
                        line-height: 11px;
                    }
                    &:last-child{
                        > ul{
                            padding-left: 20px;
                            >li{
                                color: $font_color;
                                font-size: 15px;
                                line-height: 15px;
                                display: inline-block;
                                vertical-align: top;
                                border-right: 1px solid $font_color;
                                padding-right: 10px;
                                margin-right: 10px;
                                &:last-child{
                                    padding-right: 0;
                                    margin-right: 0;
                                    border-right: none;
                                }
                            }
                        }
                    }
                }
            }
            ul.header_right{
                text-align: right;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                > li{
                    display: inline-block;
                    vertical-align: middle;
                    &.langs{
                        margin-right: 20px;
                        .dropdown{
                            a{
                                color: $white;
                                @include anim;
                                span{
                                    padding-left: 10px;
                                    display: inline-block;
                                }
                            }
                            .btn{
                                background: transparent;
                                padding: 1.5px 10px;
                                border: 1px solid #555;
                                border-radius: 0;
                                background-position: right 10px center;
                                background-image: url('./../img/langs_arr.png');
                                background-repeat: no-repeat;
                                padding-right: 30px;
                                @include anim;
                                &:hover{
                                    a{
                                        color: $accent_color;
                                    }
                                    background-image: url('./../img/langs_arr_a.png');
                                }
                            }
                            .dropdown-menu{
                                background: $theme_color1;
                                min-width: 0;
                                border: 1px solid #555;
                                padding: 0;
                                width: 100%;
                                border-radius: 0;
                                margin: -1px 0 0 0;
                                > li{
                                    border-bottom: 1px solid #555;
                                    &:last-child{
                                        border-bottom: none;
                                    }
                                    > a{
                                        &:hover{
                                            background: transparent;
                                            color: $accent_color;
                                        }
                                        @include anim;
                                        display: flex;
                                        display: -webkit-box;
                                        display: -webkit-flex;
                                        display: -ms-flexbox;
                                        -webkit-align-items: center;
                                        -moz-align-items: center;
                                        -ms-align-items: center;
                                        -ms-flex-align: center;
                                        align-items: center;
                                        text-decoration: none;
                                        padding: 5px 10px;
                                        line-height: 1;
                                        font-size: 13px;

                                    }
                                }
                            }
                        }
                    }
                    &.login{
                        margin-right: 20px;
                        a{
                            @include anim;
                            font-size: 11px;
                            line-height: 11px;
                            display: inline-block;
                            color: $white;
                            text-transform: uppercase;
                            &:hover{
                                color: $accent_color;
                                &:after{
                                    color: $white;
                                }
                            }
                            &:last-child{
                                &:after{
                                    display: none;
                                }
                            }
                            &:after{
                                content: "/";
                                margin: 0 2px;
                            }
                        }
                    }
                    &.social{
                        > a{
                            display: inline-block;
                            margin-right: 15px;
                            &:last-child{
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
        &.header_bottom{
            div.logo_box{
                position: relative;
                padding-top: 30px;
                div.rwd_btn{
                    display: none;
                    @media only screen and (max-width: 991px) {
                        display: block;
                        position: absolute;
                        width: 30px;
                        right: 0;
                        top: 55px;
                        > div{
                            width: 100%;
                            height: 3px;
                            margin-bottom: 3px;
                            background: $accent_color;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
                img{
                    max-width: 100%;
                    height: auto;
                    @media only screen and (max-width: 991px) {
                        max-width: 100px;
                    }
                }
            }
            div.menu_box{
                text-align: right;
                @media only screen and (max-width: 991px) {
                    padding-bottom: 20px;
                    display: none;
                }
                > nav.menu{
                    > ul{
                        > li{
                            display: inline-block;
                            vertical-align: top;
                            margin-right: 25px;
                            @media only screen and (max-width: 991px) {
                                margin-right: 0;
                                width: 100%;
                                text-align: center;
                            }
                            &:last-child{
                                margin-right: 0;
                            }
                            > div{
                                &.on{
                                    a{
                                        &:before{
                                            width: 100%;
                                        }
                                    }
                                }
                                > a{
                                    display: block;
                                    font-family: $main_font;
                                    font-size: 13px;
                                    font-weight: 700;
                                    font-style: normal;
                                    text-transform: uppercase;
                                    letter-spacing: .1em;
                                    text-decoration: none;
                                    color: $white;
                                    padding: 48px 0 0 0;
                                    position: relative;
                                    @media only screen and (max-width: 991px) {
                                        padding: 20px 0 0 0 !important;
                                    }
                                    &:before{
                                        position: absolute;
                                        bottom: 100%;
                                        left: 0;
                                        width: 0;
                                        @include anim;
                                        border-top: 2px solid $accent_color;
                                        content: '';
                                    }
                                    &:hover{
                                        &:before{
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

section.slider{
    height: 100vh;
    width: 100%;
    position: relative;
    > ul{
        background: $theme_color1;
        @include fill;
        > li{
            @include fill;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .slider_content{
                padding-top: 20vw;
                color: $white;
                text-align: center;
                font-size: 30px;
                line-height: 50px;
                @media only screen and (max-width: 1500px) {
                    font-size: 25px;
                    line-height: 45px;
                }
                @media only screen and (max-width: 991px) {
                    font-size: 20px;
                    line-height: 25px;
                    padding-top: 70vw;
                }
                p,h1,a{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                p{
                    font-family: $special_font;
                    margin-bottom: 15px;
                }
                h1{
                    font-size: 120px;
                    line-height: 120px;
                    text-transform: uppercase;
                    font-weight: 900;
                    margin-bottom: 30px;
                    @media only screen and (max-width: 1500px) {
                        font-size: 80px;
                        line-height: 80px;
                    }
                    @media only screen and (max-width: 991px) {
                        font-size: 40px;
                        line-height: 40px;
                    }
                }
            }
        }
    }
    div.slider_nav{
        width: 100px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 3;
        @media only screen and (max-width: 991px) {
            width: 50px;
            .vertical_align{
                > div{
                    > div{
                        vertical-align: bottom;
                    }
                }
            }
        }
        .slider_dots{
            ul{
                li{
                    text-align: center;
                    margin-bottom: 30px;
                    color: $white;
                    font-size: 18px;
                    cursor: pointer;
                    height: 50px;
                    line-height: 50px;
                    @media only screen and (max-width: 991px) {
                        font-size: 14px;
                        height: 30px;
                        line-height: 30px;
                    }
                    p{
                        display: inline-block;
                        @include anim;
                        padding: 0 5px;
                        &:after{
                            @include anim;
                            content:'';
                            width: 0%;
                            height: 1px;
                            background: $white;
                            position: absolute;
                            top: 55%;
                            left: 50%;
                            transform: rotate(-20deg);
                        }
                    }
                    &:last-child{
                        margin-bottom: 0;
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                        }
                    }
                    &:hover{
                        p{
                            font-family: $special_font;
                            font-stretch: italic;
                            color: $accent_color;
                            font-size: 20px;
                            position: relative;
                            transform: scale(1.7);
                            @media only screen and (max-width: 991px) {
                                transform: scale(1.3);
                            }
                            &:after{
                                left: 0;
                                width: 100%;
                            }
                        }
                    }
                    &.on{
                        p{
                            font-family: $special_font;
                            font-stretch: italic;
                            color: $accent_color;
                            font-size: 20px;
                            position: relative;
                            transform: scale(1.7);
                            @media only screen and (max-width: 991px) {
                                transform: scale(1.3);
                                font-size: 18px;
                            }
                            &:after{
                                left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .go_bottom{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 50px;
        z-index: 3;
        @media only screen and (max-width: 1500px) {
            bottom: 20px;
        }
        @keyframes rev-ani-mouse{
            0% {top:29%; opacity: 1;}
            30%{top:50%;}
            100% {top:50%; opacity: 0;}
          }
        div.anim_icon{
            width: 35px;
            border: 3px solid $white;
            border-radius: 23px;
            height: 0;
            padding-bottom: 55px;
            margin: 0 auto;
            position: relative;
            cursor: pointer;
            > div{
                position: absolute;
                display: block;
                top: 29%;
                left: 50%;
                width: 8px;
                height: 8px;
                margin: -4px 0 0 -4px;
                background: #fff;
                border-radius: 50%;
                animation: rev-ani-mouse 1.2s linear infinite;

            }
        }
    }
}

.section_header{
    font-size: 14px;
    line-height: 14px;
    color: $accent_color;
    letter-spacing: 0.2em;
    margin-bottom: 30px;
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    p{
        text-transform: uppercase;
    }
    div.default_font{
        padding-top: 15px;
        font-family: $special_font;
        letter-spacing: 0.1em;
        color: #363636;
    }
}

//.image{
//    background: #eeeeee;
//}

.features{
    padding: 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    div.features_list{
        text-align: center;
        padding-top: 70px;
        @media only screen and (max-width: 1500px) {
            padding-top: 30px;
        }
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    margin-bottom: 30px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                .icon{
                    width: 60px;
                    height: 0;
                    padding-bottom: 60px;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% auto;
                    background-image: url('./../img/research.png');
                    margin: 0 auto 20px auto;
                }
                header{
                    text-transform: uppercase;
                    margin-bottom: 15px;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.short_about{
    padding: 0;
    background: $theme_color2;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    &.main_short_about{
        //padding: 0 0 120px 0;
        padding: 0;
        background: #f8f7fc;
        div.cont{
            padding: 80px 0 80px 0;
            position: relative;
            @media only screen and (max-width: 1500px) {
                padding: 50px 0 50px 0;
            }
            .background_img{
                width: 100%;
                height: 0%;
                padding-bottom: 58%;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
        div.squares{
            width: 100%;
            display: table;
            min-height: 340px;
            position: relative;
            z-index: 3;
            @media only screen and (max-width: 991px) {
                display: block;
            }
            div.square_box{
                display: table-cell;
                height: 100%;
                width: 33.33%;
                -webkit-box-shadow: 9px -19px 101px rgba(51,51,51,0.3);
                -moz-box-shadow: 9px -19px 101px rgba(51,51,51,0.3);
                box-shadow: 9px -19px 101px rgba(51,51,51,0.3);
                background: $white;
                @media only screen and (max-width: 991px) {
                    display: block;
                    width: 100%;
                    margin-bottom: 15px;
                }
                > div{
                    padding: 50px;
                    > div{
                        &.image{
                            width: 60px;
                            height: 0;
                            padding-bottom: 60px;
                            background-repeat: no-repeat;
                            background-position: center;
                            background-size: contain;
                            background-image: url(./../img/research.png);
                            background-color: transparent;
                            margin-bottom: 20px;
                            @media only screen and (max-width: 991px) {
                                margin: 0 auto 20px auto;
                            }
                        }
                        &.desc{
                            font-size: 13px;
                            line-height: 23px;
                            color: $font_color;
                            header{
                                margin-bottom: 15px;
                                div.default_font{
                                    h3{
                                        @include anim;
                                        margin-bottom: 0;
                                    }
                                }
                            }
                            p{
                                strong{
                                    color: $header_color;
                                }
                            }
                            .default_font{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    div{
        &.container{
            > .row{
                margin: 0 -40px;
                > div{
                    padding: 0 40px;
                }
            }
        }
        &.short_desc{
            font-size: 18px;
            line-height: 30px;
            color: $font_color;
            font-weight: 300;
            p{
                font-weight: inherit;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &.desc{
            .section_header{
                padding-top: 30px;
            }
            > .default_font{
                margin-bottom: 50px;
            }
        }
        &.image{
            width: 100%;
            height: 0;
            padding-bottom: 100%;
            background-size: contain;
            @include default_bg;
            @media only screen and (max-width: 1500px) {
                padding-bottom: 95%;
            }
            @media only screen and (max-width: 991px) {
                margin-top: 50px;
            }
        }
        &.counts{
            padding-top: 10px;
            position: relative;
            z-index: 3;
            @media only screen and (max-width: 1500px) {
                padding-top: 50px;
            }
            .col-md-6{
                margin-bottom: 30px;
            }
            .col-md-4,  .col-md-3, .col-md-6{
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 30px;
                    }
                    &.number{
                        text-align: right;
                        width: 100px;
                        @media only screen and (max-width: 991px) {
                            width: 100%;
                            text-align: center;
                            margin-bottom: 10px;
                        }
                        p{
                            font-weight: 900;
                            color: $accent_color;
                            font-size: 60px;
                            line-height: 60px;
                        }
                    }
                    &.desc{
                        width: calc(100% - 100px);
                        padding-left: 15px;
                        @media only screen and (max-width: 991px) {
                            padding-left: 0;
                            width: 100%;
                        }
                        .default_font{
                            margin-bottom: 0;
                            font-size: 14px;
                            h3,p{
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

.table_boxes{
    &.blogs{
        div.desc{
            background: $theme_color2;
            div.dates{
                ul{
                    li{
                        @media only screen and (max-width: 991px) {
                            margin-bottom: 30px;
                            &:last-child{
                                margin-bottom: 0;
                            }
                        }
                        div{
                            display: inline-block;
                            vertical-align: top;
                            &.date{
                                text-align: center;
                                font-size: 47px;
                                line-height: 50px;
                                font-family: $special_font;
                                color: $accent_color;
                                margin-bottom: 10px;
                                width: 100px;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                }
                                div.default_font{
                                    font-family: $main_font;
                                    text-transform: uppercase;
                                }
                            }
                            &.short_desc{
                                width: calc(100% - 100px);
                                text-align: left;
                                padding-left: 50px;
                                padding-top: 15px;
                                @media only screen and (max-width: 991px) {
                                    width: 100%;
                                    text-align: center;
                                    padding-left: 0;
                                    padding-top: 0px;
                                }
                                h3,p{
                                    margin-bottom: 5px;
                                }
                            }
                        }
                    }
                }
            }

        }
    }
    .table_box{
        min-height: 100vh;
        display: table;
        width: 100%;
        @media only screen and (max-width: 991px) {
            display: flex;
            flex-flow: column;

        }
        > div{
            display: table-cell;
            vertical-align: middle;
            height: 100%;
            width: 50%;
            @media only screen and (max-width: 991px) {
                align-items: stretch;
                display: block;
                width: 100%;
            }
            &.image{
                @include default_bg;
                background-size: cover;
                @media only screen and (max-width: 991px) {
                    order: 1;
                    height: 0;
                    padding-bottom: 60%;
                }
            }
            &.desc{
                .date{
                    margin-bottom: 15px;
                    p{
                        padding: 10px 15px;
                        background: $accent_color;
                        color: $white;
                        display: inline-block;
                    }
                }
                padding: 5%;
                text-align: center;
                @media only screen and (max-width: 991px) {
                    order: 2;
                    padding:50px 15px;
                }
            }
        }
    }
}

.more{
    padding: 50px 0;
    text-align: center;
}

.rewards{
    background: $theme_color2;
    &.r_carousel{

        .c_content{
            overflow: hidden;
           ul{
            position: relative;
            white-space: nowrap;
            > li{
                display: inline-block;
                vertical-align: top;
                width: 25%;
                white-space: normal;
                @media only screen and (max-width: 1200px) {
                    width: 50%;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
            }
           }
        }
    }
    &.awards{
        div.col-md-3{
            @media only screen and (max-width: 991px) {
                &:last-child{
                    margin-bottom: 50px;
                }
            }
        }
    }
    > div.row{
        margin: 0;
        > div{
            padding: 0;
        }
    }


    .reward_box{
        overflow: hidden;
        height: 480px;
        padding-top: 90px;
        padding-left: 15%;
        padding-right: 15%;
        background: $theme_color2;
        @include anim;
        @media only screen and (max-width: 1500px) {
            padding-top: 50px;
            padding-left: 10%;
            padding-right: 10%;
            height: 400px;
        }
        @media only screen and (max-width: 991px) {
            height: auto;
        }
        &:hover{
            position: relative;
            z-index: 2;
            @include btn_shadow;
            transform: scale(1.05);
            > div{
                &.desc{
                    header{
                        div.default_font{
                            h3{
                                color: $accent_color;
                                strong{
                                    color: inherit;
                                }
                            }
                        }
                    }
                }
            }
        }
        > div{
            &.image{
                width: 170px;
                height: 0;
                padding-bottom: 170px;
                @include default_bg;
                background-size: contain;
                margin-bottom: 15px;
                @media only screen and (max-width: 1500px) {
                    width: 140px;
                    padding-bottom: 140px;
                }
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
            }
            &.desc{
                font-size: 13px;
                line-height: 23px;
                color: $font_color;
                header{
                    margin-bottom: 15px;
                    @media only screen and (max-width: 991px) {
                        text-align: center;
                    }
                    div.default_font{
                        h3{
                            @include anim;
                        }
                    }
                }
                p{
                    strong{
                        color: $header_color;
                    }
                }
            }
        }
    }
}

.recruitment{
    padding: 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    div{
        &.recruitment_list{
            text-align: center;
            > ul{
                margin: 0 -15px;
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 33.33%;
                    padding: 15px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    article{
                        @include anim;
                    }
                    &:hover{
                        article{
                            @include btn_shadow;
                        }
                        div{
                            &.desc{
                                .hidden_box{
                                    visibility: visible;
                                    opacity: 1;
                                    bottom: 0;
                                }
                            }
                        }
                    }
                    div{
                        &.image{
                            @include default_bg;
                            background-size: cover;
                            width: 100%;
                            height: 0;
                            padding-bottom: 60%;
                            @media only screen and (max-width: 1500px) {
                                padding-bottom: 50%;
                            }
                            @media only screen and (max-width: 991px) {
                                padding-bottom: 70%;
                            }
                        }
                        &.desc{
                            padding-left: 15px;
                            padding-right: 15px;
                            padding-top: 20px;
                            padding-bottom: 10px;
                            border: 1px solid #f4f4f4;
                            border-top: none;
                            min-height: 123px;
                            position: relative;
                            overflow: hidden;
                            .hidden_box{
                                @include anim;
                                opacity: 0;
                                visibility: hidden;
                                position: absolute;
                                left: 0;
                                bottom: 100%;
                                height: 100%;
                                width: 100%;
                                background: $theme_color1;
                                color:$white;
                                font-size: 20px;
                                line-height: 20px;
                                text-align: center;
                                text-transform: uppercase;
                            }
                            h3,p{
                                margin-bottom: 5px;
                            }
                            h3{
                                font-size: 16px;
                                line-height: 26px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.training{
    &.training{
        padding: 80px 0 50px 0;
        @media only screen and (max-width: 1500px) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    &.main_training{
        @media only screen and (max-width: 1500px) {
            padding-top: 50px;
        }
        .main_training_list{
            > ul{
                margin: 0 -15px;
                > li{
                    display: inline-block;
                    vertical-align: top;
                    width: 33.33%;
                    padding: 0 15px;
                    margin-bottom: 30px;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                    }
                    div.desc{
                        text-align: center;
                        font-size: 16px;
                        color: $font_color;
                        line-height: 30px;
                        h2{
                            font-size: 20px;
                            line-height: 30px;
                            color: $accent_color;
                            font-weight: 700;
                        }
                    }
                    &:hover{
                        article{
                            .vertical_align{
                                background: rgba(0,0,0,0.8);
                                visibility: visible;
                                p{
                                    margin-top: 0;
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    div{
        &.training_list{
            > ul{
                > li{
                    position: relative;
                    display: inline-block;
                    vertical-align: top;
                    width: 20%;
                    height: 0;
                    padding-bottom: 25%;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        padding-bottom: 100%;
                    }
                    &:hover{
                        article{
                            .vertical_align{
                                background: rgba(0,0,0,0.8);
                                visibility: visible;
                                p{
                                    margin-top: 0;
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
        .article_holder{
            position: relative;
            height: 0;
            padding-bottom: 66%;
            margin-bottom: 15px;
        }
        article{
            @include fill;
            @include default_bg;
            background-size: cover;
            .vertical_align{
                background: rgba(0,0,0,0);
                text-align: center;
                color: $white;
                font-family: $special_font;
                font-size: 18px;
                line-height: 28px;
                @include anim;
                visibility: hidden;
                p{
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.6s all ease;
                    margin-top: 60px;
                    padding-top: 60px;
                    background-repeat: no-repeat;
                    background-position: center top;
                    background-image: url('./../img/plus.png');
                }
            }
        }
    }
}

footer.footer{
    @media only screen and (max-width: 991px) {
        text-align: center;
    }
    div{
        &.f_h{
            text-transform: uppercase;
            color: $header_color;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 30px;
            @media only screen and (max-width: 991px) {
                margin-top: 30px;
            }
            p{
                font-weight: inherit;
            }
        }
        &.footer_top{
            //padding: 60px 0;
            height: 350px;
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            background: $theme_color1;
            @media only screen and (max-width: 1500px) {
                height: 300px;
            }
            @media only screen and (max-width: 1200px) {
                height: 250px;
            }
            @media only screen and (max-width: 991px) {
                height: auto;
                display: block;
            }
            > div{
                img{
                    max-width: 100%;
                    height: auto;
                    @media only screen and (max-width: 991px) {
                        margin-bottom: 20px;
                    }
                }
                &.footer_left_side{
                    width: 60%;
                    padding-top: 4%;
                    @media only screen and (max-width: 991px) {
                        padding-top: 30px;
                        width: 100%;
                        padding-bottom: 30px;
                    }
                    a{
                        color: $font_color;
                        @include anim;
                        &:hover{
                            color: $white;
                        }
                    }
                }
                &.footer_right_side{
                    width: 40%;
                    position: relative;
                    @media only screen and (max-width: 991px) {
                        width: 100%;
                        height: 250px;
                    }
                    iframe{
                        @include fill;
                        border: none;
                    }
                }
            }
           /* > div.container{
                > div.row{
                    margin: 0 -30px;
                    > div{
                        padding: 0 30px;
                    }
                }
            }*/
            /*div{
                &.logo_box{
                    margin-bottom: 30px;
                }
                form{
                    border: 1px solid #f4f4f4;
                    border-radius: 6px;
                    overflow: hidden;
                    margin-bottom: 30px;
                    input{
                        border: none;
                        outline: none;

                        &.newsletter_input{
                            width: calc(100% - 160px);
                            height: 44px;
                            padding-right: 20px;
                            padding: 0 15px;
                            font-size: 15px;
                            font-weight: 400;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                            }
                        }
                        &.newsletter_submit{
                            width: 160px;
                            text-transform: uppercase;
                            color: $header_color;
                            font-size:13px;
                            background-repeat: no-repeat;
                            background-position: right 20px top 35%;
                            background-image: url('./../img/submi_arr.png');
                            background-size: 16px;
                            background-color: $white;
                            @media only screen and (max-width: 991px) {
                                width: 100%;
                                border-top: 1px solid #f4f4f4;
                                height: 40px;
                                text-align: left;
                                padding-left: 15px;
                                background-position: right 20px center;
                            }
                        }
                    }
                }
            }*/
            nav.menu{
                > ul{
                    > li{
                        margin-bottom: 13px;
                        > div{
                            @include anim;
                            position: relative;
                            &:hover{
                                padding-left: 10px;
                                > a{
                                    padding-left: 5px;
                                    color: $accent_color;
                                    text-decoration: underline;
                                }
                            }
                            &:after{
                                content: '';
                                border-top: 1px solid $accent_color;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                width: 100%;
                                z-index: -1;
                            }
                            > a{
                                display: block;
                                background: $white;
                                color: $header_color;
                                font-size: 16px;
                                line-height: 30px;
                                @include anim;
                            }
                        }
                    }
                }
            }
        }
        &.footer_bottom{
            font-family: $special_font;
            color: $font_color;
            font-style: italic;
            font-weight: 400;
            text-align: center;
            font-size: 18px;
            line-height: 28px;
            padding: 20px 0;
            @media only screen and (max-width: 1500px) {
                font-size: 15px;
                line-height: 25px;
            }
            span{
                color: $accent_color;
            }
        }
    }
}

section.top{
    text-align: center;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
    padding: 50px 0;
    min-height: 325px;
    @media only screen and (max-width: 1500px) {
        min-height: 225px;
    }
    @media only screen and (max-width: 991px) {
        padding: 30px 0;
    }
    .nav_way{
        margin-bottom: 10px;
        > ul{
            > li{
                display: inline-block;
                vertical-align: top;
                padding-right: 20px;
                position: relative;
                margin-right: 10px;
                @media only screen and (max-width: 991px) {
                    padding-right: 0;
                    padding-bottom: 15px;
                    width: 100%;
                    text-align: center;
                }
                &:last-child{
                    margin-right: 0;
                    padding-right: 0;
                    &:after{
                        display: none;
                    }
                }
                &:after{
                    content: '/';
                    position: absolute;
                    top: 0;
                    right: 0;
                    color: $accent_color;
                    font-size: 14px;
                    @media only screen and (max-width: 991px) {
                        display: none;
                    }
                }
                > div{
                    &.on{
                        a{
                            text-decoration: underline;
                        }
                    }
                    > a{
                        color: $accent_color;
                        font-size: 14px;
                        letter-spacing: 0.2em;
                        text-transform: uppercase;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .page_header{
        font-size: 18px;
        line-height: 32px;
        font-family: $special_font;
        color: $font_color;
        div.default_font{
            margin-bottom: 10px;
        }
        letter-spacing: 0.1em;
        strong{
            color: $accent_color;
        }
    }
}

.about{
    &.about_new{
        padding-top: 50px;
        .container{
            > .row{
                margin: 0 -20px;
                > div{
                    padding: 0 30px;
                }
            }
        }
        .images{
            > ul{
                > li{
                    margin-bottom: 80px;
                    div.image{
                        width: 100%;
                        height: 0;
                        padding-bottom: 66%;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                }
            }
        }
    }
    padding: 0;
    @media only screen and (max-width: 991px) {
        padding: 50px 0;
    }
    > .container{
        > div.row{
            margin: 0 -20px;
            > div{
                padding: 0 40px;
            }
        }
    }
    .desc{
        font-size: 16px;
        line-height: 30px;
        font-family: $special_font;
        color: $font_color;
        letter-spacing: 0.16em;
        p{
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    div.who{
        margin-top: 30px;
        @media only screen and (max-width: 991px) {
            text-align: center;
            margin-bottom: 50px;
        }
        img{
            max-width: 100%;
            height: auto;
        }
        .author{
            margin-top: 15px;
            font-size: 18px;
            line-height: 28px;
            em{
                color: $accent_color;
                font-weight: 300;
            }
        }
    }
    div.progress_bars{
        ul{
            li{
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                color: $font_color;
                margin-bottom: 20px;
                @media only screen and (max-width: 991px) {
                    text-align: center;
                }
                div.row{
                    margin-bottom: 15px;
                }
                div.p_bar{
                    height: 8px;
                    background-color: #f4f4f4;
                    width: 100%;
                    border-radius: 2px;
                    position: relative;
                    > div{
                        border-radius: 2px;
                        background: $accent_color;
                        height: 100%;
                        top: 0;
                        left: 0;
                        position: absolute;
                        div.default_font{
                            font-weight: 600;
                            position: absolute;
                            bottom: 100%;
                            left: 100%;
                            p{
                                margin-bottom: 15px;
                                font-weight: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
}

.banner{
    min-height: 90vh;
    position: relative;
    margin-top: -172px;
    @include default_bg;
    background-size: cover;
    @media only screen and (max-width: 991px) {
        min-height: 100vh;
        margin-top: 0;
    }
    .section_header{
        div.default_font{
            h2{
                color: $white;
            }
        }
    }
}

.team{
    background: $theme_color2;
    padding: 80px 0 0 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0 0 0;
    }
    .members{
       overflow: hidden;
       > ul{
           position: relative;
           white-space: nowrap;
           > li{
               position: relative;
                width: 25%;
                height: 0;
                padding-bottom: 25%;
                display: inline-block;
                vertical-align: top;
                white-space: normal;
                @include default_bg;
                background-size: cover;
                cursor: pointer;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    padding-bottom: 66%;
                }
                .vertical_align{
                    background: rgba(0,0,0,0);
                    text-align: center;
                    color: $white;
                    font-family: $special_font;
                    font-size: 18px;
                    line-height: 28px;
                    @include anim;
                    visibility: hidden;
                    p{
                        visibility: hidden;
                        opacity: 0;
                        transition: 0.6s all ease;
                        margin-top: 80px;
                    }
                }
                &:hover{
                    .vertical_align{
                        background: rgba(0,0,0,0.8);
                        visibility: visible;
                        p{
                            margin-top: 0;
                            visibility: visible;
                            opacity: 1;
                        }
                        em{
                            color: $accent_color;
                        }
                    }
                }
                .member_about{
                    visibility: hidden;
                    opacity: 0;
                }
                &.active{
                    .vertical_align{
                        background: rgba(0,0,0,0.8);
                        visibility: visible;
                        p{
                            margin-top: 0;
                            visibility: visible;
                            opacity: 1;
                        }
                        em{
                            color: $accent_color;
                        }
                    }
                }
           }
       }
    }
    .arrs{
        display: none;
        padding-top: 50px;
        ul{
            position: relative;
            top: -25px;
            text-align: center;
            li{
                display: inline-block;
                vertical-align: top;
                cursor: pointer;
                margin-right: 50px;
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
    .desc{
        display: none;
        padding: 50px 0;
        @media only screen and (max-width: 991px) {
            padding: 0 0 50px 0;
        }
        .member_about{
            text-align: center;

            header{
                h2{
                    margin-bottom: 0;
                    font-family: $special_font;
                    font-style: italic;
                }
                margin-bottom: 30px;
                em{
                    color: $accent_color;
                }
            }
        }
    }
}

section.reviews{
    padding: 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    .reviews_carousel{
        margin-top: 30px;
        overflow: hidden;
        @media only screen and (max-width: 991px) {
            margin: 0;
        }
        > ul{
            position: relative;
            white-space: nowrap;
            margin: 0 -15px;
            > li{
                white-space: normal;
                width: 33.33%;
                display: inline-block;
                vertical-align: top;
                padding: 0 15px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    text-align: center;
                }
                > div.desc{
                    margin-bottom: 15px;
                }
                div.desc{
                    color: $header_color;
                    font-size: 15px;
                    line-height: 30px;
                }
                div.img{
                    width: 40px;
                    height: 0;
                    padding-bottom: 40px;
                    @include default_bg;
                    background-size: cover;
                    border-radius: 50%;
                    display: inline-block;
                    vertical-align: top;
                    @media only screen and (max-width: 991px) {
                        display: block;
                        margin: 0 auto 20px auto;
                    }
                }
                div.name{
                    display: inline-block;
                    vertical-align: middle;
                    width: calc(100% - 40px);
                    padding-left: 15px;
                    @media only screen and (max-width: 991px) {
                        display: block;
                        width: 100%;
                        padding-left: 0;
                    }
                    div.desc{
                        color: $font_color;
                        strong{
                            color: $header_color;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

section.contact{
    padding-top: 80px;
    padding-bottom: 50px;
    @media only screen and (max-width: 1500px) {
        padding-top: 50px;
        padding-bottom: 0;
    }
    div.contact_box{
        padding-left: 60px;
        background-position: left top;
        background-repeat: no-repeat;
        @media only screen and (max-width: 991px) {
            padding-left: 0;
            padding-top: 60px;
            background-position: center top;
            text-align: center;
        }
        &.phone{
            background-image: url('./../img/phone.png');
            @media only screen and (max-width: 991px) {
                margin-bottom: 50px;
            }
        }
        &.adress{
            background-image: url('./../img/pin.png');
            @media only screen and (max-width: 991px) {
                margin-bottom: 50px;
            }
        }
        &.mail{
            background-image: url('./../img/mail.png');
        }
        header{
            h2{
                font-size: 20px;
                line-height: 30px;
            }
            margin-bottom: 15px;
        }
    }
    .section_header{
        margin-top: 80px;
        margin-bottom: 10px;
        @media only screen and (max-width: 1500px) {
            margin-top: 50px;
        }
    }
    .short_desc{
        font-size: 18px;
        line-height: 30px;
        color: $font_color;
        font-weight: 300;
        margin-bottom: 60px;
        p{
            font-weight: inherit;
            margin-bottom: 20px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    div.holder{
        margin-bottom: 40px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 30px;
        }
        input{
            outline: none;
            &.input{
                width: 100%;
                height: 40px;
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 10px;
                padding: 0 10px;
                border: none;
                border-bottom: 1px solid #ebebeb;
                background-color: transparent;
                color: $font_color;
            }
            &.submit{
                font-size: 13px;
                line-height: 20px;
                color: $white;
                background-color: $accent_color;
                border: none;
                height: 45px;
                width: 150px;
                text-transform: uppercase;
                text-align: center;
                border-radius: 5px;
                padding-right: 20px;
                background-image: url('./../img/submi_arr_2.png');
                background-position: center right 30px;
                background-repeat: no-repeat;
                background-size: 15px;
                text-transform: uppercase;
                @include anim;

                &:hover{
                    background-color: $theme_color1;
                }
            }
        }
        .textarea{
            width: 100%;
            height: 140px;
            font-weight: 400;
            font-size: 16px;
            margin-bottom: 10px;
            padding: 0 10px;
            border: none;
            border-bottom: 1px solid #ebebeb;
            background-color: transparent;
            resize: none;
            outline: none;
            color: $font_color;
        }
        ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $font_color;
          }
          ::-moz-placeholder { /* Firefox 19+ */
            color: $font_color;
          }
          :-ms-input-placeholder { /* IE 10+ */
            color: $font_color;
          }
          :-moz-placeholder { /* Firefox 18- */
            color: $font_color;
          }
    }
    .contact_desc_list{
        padding-bottom: 80px;
        @media only screen and (max-width: 1500px) {
            padding-bottom: 30px;
        }
        > ul{
            > li{
                width: 100%;
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0;
                }
                div.desc{
                    padding-left: 120px;
                    background-repeat: no-repeat;
                    background-position: left center;
                    background-size: 100px;
                    @media only screen and (max-width: 991px) {
                        padding-left: 0;
                        text-align: center;
                        padding-top: 120px;
                        background-position: center top;
                    }
                }
            }
        }
    }
}

section.map{
    height: 500px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 1500px) {
        height: 300px;
    }
    > iframe{
        border: none;
        @include fill;
    }
}

section.details{
    &.d_new{
        .details_top{
            margin-bottom: 50px;
            div.main_image{
                padding-bottom: 80%;
                @media only screen and (max-width: 1500px) {
                    margin-bottom: 30px;
                }
                @media only screen and (max-width: 991px) {
                    margin-bottom: 0;
                    margin-top: 50px;
                }
            }
        }
    }

    padding: 80px 0;
    @media only screen and (max-width: 1500px) {
        padding: 50px 0;
    }
    div.main_image{
        width: 100%;
        height: 0;
        padding-bottom: 50%;
        @include default_bg;
        background-size: cover;
        margin-bottom: 50px;
        @media only screen and (max-width: 1500px) {
            margin-bottom: 30px;
        }
    }
    div.gallery{
        padding-top: 50px;
        @media only screen and (max-width: 1500px) {
            padding-top: 30px;
        }
        ul{
            margin: 0 -15px;
            li{
                display: inline-block;
                vertical-align: top;
                width: 33.33%;
                position: relative;
                padding-left: 15px;
                padding-right: 15px;
                position: relative;
                margin-bottom: 30px;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                }
                &:hover{
                    .vertical_align{
                        background: rgba(0,0,0,0.8);
                        visibility: visible;
                        p{
                            margin-top: 0;
                            visibility: visible;
                            opacity: 1;
                            padding-top: 0;
                        }
                    }
                }
                div.img{
                    @include default_bg;
                     background-size: cover;
                     width: 100%;
                     height: 0;
                     padding-bottom: 66%;
                     position: relative;
                }
                .vertical_align{
                    background: rgba(0,0,0,0);
                    text-align: center;
                    color: $white;
                    font-family: $special_font;
                    font-size: 18px;
                    line-height: 28px;
                    @include anim;
                    visibility: hidden;
                    p{
                        visibility: hidden;
                        opacity: 0;
                        transition: 0.6s all ease;
                        margin-top: 60px;
                        padding-top: 60px;
                    }
                }
            }
        }
    }
}

.clients{
    background: $theme_color2;
    .clients_list{
        overflow: hidden;
        > ul{
            position: relative;
            white-space: nowrap;
            > li{
                white-space: normal;
                display: inline-block;
                vertical-align: text-top;
                width: 12.5%;
                height: 0;
                padding-bottom: 12.5%;
                @media only screen and (max-width: 991px) {
                    width: 100%;
                    height: 0;
                    padding-bottom: 100%;
                }
                @include default_bg;
                background-size: contain;
                @include anim;
                background-color: $theme_color2;
                &:hover{
                    @include btn_shadow;
                    transform: scale(1.05);
                }
            }
        }
    }
}
